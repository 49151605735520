<template>
    <div class="mianbox">
        <div class="searchbox">
            <el-form ref="form" :model="form" label-width="80px" class="searchbox_left">
                <div class="leftbox">
                    <div class="left_one">
                        <el-input
                            placeholder="请输入人员关键词查询"
                            v-model="form.name"
                            size="small">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div class="left_two">
                        <el-form-item label="部门查询">
                            <el-select v-model="value" filterable placeholder="请选择部门"  size="small">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                size="small"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div> 
                    <div class="left_two">
                        <el-form-item label="角色查询">
                            <el-select v-model="value1" filterable placeholder="请选择角色"  size="small">
                                <el-option
                                v-for="item in options1"
                                :key="item.value1"
                                :label="item.label"
                                :value="item.value1"
                                size="small"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div> 
                    <div class="left_three">
                        <el-button type="primary" size="small" plain  @click="onSubmit">查询</el-button>
                    </div> 
                </div>
            </el-form>
            <div class="rightbox">
                <el-button type="primary" size="small" @click="newbuild()">添加人员</el-button>
            </div> 
        </div>
        <!--新建角色弹窗-->
        <el-dialog title="创建角色" :visible.sync="dialogFormVisible" width="30%">
            <el-form ref="newform" :model="newform" label-width="100px" :rules="rules">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="newform.name" placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="newform.phone" placeholder="请填写联系电话"></el-input>
                </el-form-item>
                
                <el-form-item label="所属部门" label-width="100px" prop="department">
                    <el-select 
                        v-model="newform.department" 
                        filterable 
                        placeholder="请选择所属部门" 
                        style="width:100%">
                        <el-option
                            v-for="item in departmentlist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="角色" label-width="100px" prop="role">
                    <el-select 
                        v-model="newform.role" 
                        filterable 
                        placeholder="请选择所属部门" 
                        style="width:100%">
                        <el-option
                            v-for="item in rolelist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel()">取 消</el-button>
                <el-button type="primary" @click="determine('newform')">确 定</el-button>
            </div>
        </el-dialog>
        <div class="Tablebox">
            <el-table
            :data="tableData"
            border
            :style="{width: '100%','margin-bottom':'2rem'}"
            :header-cell-style="{background:'#f5f7fa'}">
                <el-table-column v-for="(item, index) in tableList" :key="index" :prop="item.prop" :label="item.label"></el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120">
                    <template slot-scope="scope">
                       
                        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
                        
                        <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" slot="reference">删除</el-button>
                        
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="paging.currentPage"
                :page-sizes="[10]"
                :page-size="paging.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                //新增
                newform: {
                    name: '',
                    phone:'',
                    department:[],
                    role:[]
                },
                rules: {
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: '请输入联系电话', trigger: 'blur' },
                    ],
                    department:[
                        { required: true, message: '请选择所属部门', trigger: 'blur' },
                    ],
                    role:[
                        { required: true, message: '请选择角色', trigger: 'blur' },
                    ]
                },
                dialogFormVisible: false,
                //部门列表
                departmentlist:[
                    {
                        value: '1',
                        label: '不良一部'
                    },
                    {
                        value: '2',
                        label: '不良二部'
                    },
                    {
                        value: '3',
                        label: '不良三部'
                    },
                ],
                //角色列表
                rolelist:[
                    {
                        value: '1',
                        label: '管理员'
                    },{
                        value: '2',
                        label: '部门经理'
                    },{
                        value: '3',
                        label: '成员'
                    },
                ],
                 //列表展示的值
                tableList:[
                    {
                        prop:'number',
                        label:'编号'
                    },
                    {
                        prop:'name',
                        label:'人员名称'
                    },
                    {
                        prop:'department',
                        label:'所属部门'
                    },
                    {
                        prop:'role',
                        label:'角色'
                    },
                    {
                        prop:'Phone',
                        label:'联系电话'
                    }
                ],
                //级联选择器
                options: [
                    {
                        value: 'null',
                        label: '不限'
                    },
                    {
                        value: '1',
                        label: '不良一部'
                    },
                    {
                        value: '2',
                        label: '不良二部'
                    }, 
                    {
                        value: '3',
                        label: '不良三部'
                    }
                ],
                options1: [
                    {
                        value: 'null',
                        label: '不限'
                    },
                    {
                        value: '1',
                        label: '管理员'
                    },
                    {
                        value: '2',
                        label: '部门经理'
                    }, 
                    {
                        value: '3',
                        label: '成员'
                    }
                ],
                value:'',
                value1:'',
                //搜索关键词
                form: {
                    name: '',
                    handled:[],
                },
                //表单数据
                 tableData: [
                    {
                        number: '1',//编号
                        name: '张三',//名称
                        department:'不良一部',//部门
                        role:'部门经理',//角色
                        Phone:'1355***8462'
                    },{
                        number: '2',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    },{
                        number: '3',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    },{
                        number: '4',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    },{
                        number: '5',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    },{
                        number: '6',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    },{
                        number: '7',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    },{
                        number: '8',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    },{
                        number: '9',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    },{
                        number: '10',
                        name: '-',
                        department:'-',
                        role:'-',
                        Phone:'-'
                    }
                ],
                //分页
                paging:{
                    currentPage:4,//当前加载页
                    size:10,//每页显示条目个数
                    total:400,//总条目数
                }
                
                
            }
        },
        methods: {
             //新建角色
            newbuild(){
                this.dialogFormVisible=true
            },
            cancel(){
                this.dialogFormVisible=false
            },
            determine(newform){
                console.log(this.newform)
                this.$refs[newform].validate((valid) => {
                if (valid) {
                    this.$message({
                        message: '创建成功',
                        type: 'success'
                    });
                    //this.newform.name='';
                    //this.newform.manager=[];
                    this.dialogFormVisible=false   
                } else {
                    this.$message.error('请正确填写信息');
                    }
                });
            },
            //查询按钮
            onSubmit() {
                console.log(this.form);
            },
            //查看
            handleClick(row) {
                console.log(row);
            },
            //删除
            handleDelete(index, row){
                this.$confirm('您确定删除编号为：'+row.number+'的文件吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
                .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                });
                console.log(index, row);
            },
            //分页
            //当前加载页 改变时会触发
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            }
        }
        
    }
</script>

<style lang="less" scoped>
    .mianbox{
        padding: 1.5rem;
        .searchbox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .searchbox_left{
                .leftbox{
                    display: flex;
                    align-items: center;
                    .left_one{
                        width: 26.8rem;
                    }
                    .left_two{
                        width: 28rem;
                        margin-left:2rem ;
                        .el-form-item{
                            margin-bottom: 0 !important;
                        }
                    }
                    .left_three{
                        margin-left:2rem ;
                    }
                }
            }
            // .rightbox{

            // }
        }
        .Tablebox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            margin-top: 1.5rem;
        }
    }
</style>